import React from 'react';
import './PlansPricing.css';

const PlansPricing = () => {
  return (
    <div className="plans-pricing-container">
      <h1>Plans & Pricing</h1>
      <div className="prices-container">
        <div className="plan">
          <h2>Pro Plan</h2>
          <p>$39 per month</p>
          
          <div className="subscription-options">
  <button>Subscribe →</button>
  <a href="#">Save with yearly (6+ months free) ↗</a>
</div>
          <ul>
            <li>Create 1,000 interior designs</li>
            <li>Create up to 4 designs in parallel</li>
            <li>Write your own prompts</li>
            <li>Copy any photo</li>
            <li>Commercial use license</li>
          </ul>
        </div>
        <div className="plan popular">
          <h2>Most popular</h2>
          <h2>Premium Plan</h2>
          <p>$99 per month</p>
          <div className="subscription-options">
  <button>Subscribe →</button>
  <a href="#">Save with yearly (6+ months free) ↗</a>
</div>
          <ul>
            <li>Create 5,000 interior designs</li>
            <li>All Pro features, plus:</li>
            <li>Create up to 8 designs in parallel</li>
            <li>Use the magic photo editor</li>
            <li>Make fly-through videos from designs</li>
            <li>Access to the community chat</li>
            <li>Early access to new features</li>
          </ul>
        </div>
        <div className="plan">
          <h2>Business Plan</h2>
          <p>$299 per month</p>
          <div className="subscription-options">
  <button>Subscribe →</button>
  <a href="#">Save with yearly (6+ months free) ↗</a>
</div>
          <ul>
            <li>Create 25,000 interior designs</li>
            <li>All Premium features, plus:</li>
            <li>Create up to 16 designs in parallel</li>
            <li>Unlimited designs storage</li>
            <li>Priority: faster response times</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PlansPricing;