import React, { useState } from 'react';
import './HomepageContent.css'; // 引入新的CSS文件

const HomepageContent = () => {
  const [userId, setUserId] = useState('');

  return (
    <>
      <div className="welcome-content">
      <div className="centered-content">
      <h1 className="title-left-align">🔓Unlock Infinite Game Assets</h1>
  <ul className="custom-list">
  <li>Create breathtaking game item icons with the first AI-powered Item Generator! Craft unique, high-quality assets for your games or projects effortlessly. Save time and money by generating icons from your laptop or phone instead of hiring costly designers.</li>
  <li>✏️ Customize your own AI model with diverse options and styles</li>
  <li>🎮 Generate 100% AI-designed icons in any theme, genre, or aesthetic</li>
  <li>🛍️ Visualize items on your characters for your online store or portfolio</li>
  <li>🚀 Animate your icons to bring them to life in your game</li>
  <li>🎁 Discover icon packs like Tech Titans and Fantasy Forge</li>
  <li>Experience the future of game design with our AI-driven platform—where your imagination is the only limit!</li>
</ul>
</div>
        <div className="right-content">
        <div className="inner-black-box">
        <input
              type="text"
              className="input-field"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              placeholder="请输入用户ID"
            />
            <div className="separator">------or------</div>
            <button className="login-button">登录</button>
            <p className="small-text">If you already have an account, we'll log you in</p>
        </div></div>
      </div>
    </>
  );
};

export default HomepageContent;