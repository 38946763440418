import React, { useState } from 'react';
import './ImageGenerator.css';

const ImageGenerator = () => {
  const [category, setCategory] = useState('科技');
  const [style, setStyle] = useState('游戏风格');
  const [itemType, setItemType] = useState('物品图标');
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const generateImage = async () => {
    // 这里是生成图像的逻辑，根据实际情况调整
    setLoading(true);
    // 假设这里是调用API的代码
    console.log(category, style, itemType);
    // 模拟API调用结束
    setLoading(false);
  };

  return (
    <div className="image-generator">
      <h1>AI Image Generator</h1>
      <div className="input-container">
        <select className="input-field" value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="科技">科技</option>
          <option value="医疗">医疗</option>
          <option value="交通">交通</option>
          <option value="宇宙">宇宙</option>
        </select>
        <select className="input-field" value={style} onChange={(e) => setStyle(e.target.value)}>
          <option value="游戏风格">游戏风格</option>
          <option value="卡通风格">卡通风格</option>
          <option value="像素风格">像素风格</option>
          <option value="抽象风格">抽象风格</option>
        </select>
        <select className="input-field" value={itemType} onChange={(e) => setItemType(e.target.value)}>
          <option value="物品图标">物品图标</option>
          <option value="徽章">徽章</option>
          <option value="logo">logo</option>
          <option value="横幅广告">横幅广告</option>
        </select>
        <button onClick={generateImage} disabled={loading} className="generate-button">
          {loading ? 'Generating...' : 'Generate Image'}
        </button>
      </div>
      {imageUrl && (
        <div className="image-container">
          <img src={imageUrl} alt="Generated image" className="generated-image" />
        </div>
      )}
    </div>
  );
};

export default ImageGenerator;