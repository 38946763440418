import React, { useState } from 'react';
import './Navbar.css';

const Navbar = () => {
  const [isRotating, setIsRotating] = useState(false);

  const toggleRotation = () => {
    setIsRotating(!isRotating);
  };

  return (
    <div className="navbar">
      <a href="/" className="navbar-logo" onClick={toggleRotation}>
        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Logo" className={isRotating ? 'rotate' : ''} />
      </a>
      <div className="navbar-links">
        <a href="/pricing">Pricing</a>
        <a href="/index">Log in</a>
        <a href="/billing">Billing</a>
        <a href="/gallery">Gallery</a>
      </div>
    </div>
  );
};

export default Navbar;