import React from 'react';
import './Footer.css'; // 确保您也创建了相应的CSS文件

const Footer = () => {
  const currentYear = new Date().getFullYear(); // 获取当前年份

  return (
    <footer className="footer">
      <div className="footer-left">
        <span>gameaicraft.com Inc.</span>
      </div>
      <div className="footer-center">
        <span>© {currentYear} All rights reserved.</span>  
      </div>
      <div className="footer-links">
      <a href="/terms">Terms</a>
  <a href="/privacy">Privacy</a>
  <a href="/contact">Contact</a>
      </div>
    </footer>
  );
};

export default Footer;