import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import ImageGenerator from './components/ImageGenerator';
import Footer from './components/Footer';
import HomepageContent from './components/HomepageContent';
import PlansPricing from './components/PlansPricing'; // Make sure this import is added

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
  <Route path="/" element={<HomepageContent />} />
  <Route path="/index" element={<ImageGenerator />} />
  <Route path="/pricing" element={<PlansPricing />} /> {/* Updated from /plans-pricing to /pricing */}
</Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;